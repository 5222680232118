import { Component, OnInit, ViewChild} from '@angular/core';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { AppMinimize } from '@ionic-native/app-minimize/ngx';
import { Location } from '@angular/common';
import { MylangService } from './services/mylang.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { MyurlService } from './services/myurl.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;
  applang = 1;
  myurl: string;
  constructor(private platform: Platform, private appMinimize: AppMinimize, private location: Location,
    private mylang: MylangService, private myurlserv: MyurlService, private http: HttpClient) {}
  ngOnInit()
  {
    this.initbacklisten();
    //language setting start
    this.mylang.lang.subscribe((e)=>{
      this.applang = Number(e);
    });
    //languagte setting end
    this.myurl = this.myurlserv.getmyurl();
    //this.initpushnotif();

  }
  initbacklisten()
  {
    this.platform.ready().then(()=>{
      this.platform.backButton.subscribeWithPriority(10, () => {
        console.log('BACK BUTTON PRESSED');
        if (this.routerOutlet.canGoBack()){
          console.log('GOING BACK');
          this.location.back();
        }
        else
        {
          this.appMinimize.minimize();
        }
     });
    });
  }
  testclick()
  {
    this.mylang.setlang(1);
    this.applang = 1;
  }
  testclick2()
  {
    this.mylang.setlang(2);
    this.applang = 2;
  }

initpushnotif()
{

console.log('called push init');

  const addListeners = async () => {
    await PushNotifications.addListener('registration', token => {
      console.log('Registration token: ', token.value);
      this.http.get<any>(this.myurl + 'registertoken.php?token=' + token.value).subscribe((ex)=>{
        console.log('sent');
      });
    });

    // for error
    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });

    // successful receipt of notification
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
    });
    // notification clicked
    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
  };

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
    await PushNotifications.register();
  };

  const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  };
}


}
