import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { AppMinimize } from '@ionic-native/app-minimize/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';

import { Downloader } from '@ionic-native/downloader/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';




@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, LaunchNavigator, AppMinimize, FileTransfer,
    File, FileTransferObject, Downloader, InAppBrowser, PhotoViewer],
  bootstrap: [AppComponent],
})
export class AppModule {}
