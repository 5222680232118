import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class MylangService {
  lx = 1;
lang: Subject<number>;
  constructor() {
    this.lang = new Subject<number>();
    this.lang.next(Number(this.lx));
    this.getlang();
  }


getlang(){
  Storage.get({key : 'applang'}).then((e)=>{
    if(!e || !e.value){
      this.setlang(1);
    }
    else
    {
      console.log('retrived language :: ' + e.value);
      this.lang.next(Number(e.value));
      this.lx = Number(e.value);
      this.sendnextval();
    }
  });
}

setlang(x: any){

  Storage.set({key:'applang', value: x + ''}).then(()=>{ console.log('stored language:: ' + x); });
  //call getlang after saving
  this.lang.next(Number(x));
  this.lx = x;
  this.sendnextval();
}

sendnextval()
{
  this.lang.next(Number(this.lx));
}
}
