import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyurlService {
baseurl  = 'https://capten.in/appengine/';
  constructor() { }

  getmyurl(){
    return this.baseurl;
  }

  setmyurl(x: string){
    this.baseurl = x;
  }


}
