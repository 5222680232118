import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/:page',
    loadChildren: () => import('./components/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'gpsresults',
    loadChildren: () => import('./components/gpsresults/gpsresults.module').then( m => m.GpsresultsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./components/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'passreset',
    loadChildren: () => import('./components/passreset/passreset.module').then( m => m.PassresetPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./components/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'mybusiness',
    loadChildren: () => import('./components/mybusiness/mybusiness.module').then( m => m.MybusinessPageModule)
  },
  {
    path: 'addbiz',
    loadChildren: () => import('./components/addbiz/addbiz.module').then( m => m.AddbizPageModule)
  },
  {
    path: 'verifylisting',
    loadChildren: () => import('./components/verifylisting/verifylisting.module').then( m => m.VerifylistingPageModule)
  },
  {
    path: 'verifylisting/:id',
    loadChildren: () => import('./components/verifylisting/verifylisting.module').then( m => m.VerifylistingPageModule)
  },
  {
    path: 'viewbiz',
    loadChildren: () => import('./components/viewbiz/viewbiz.module').then( m => m.ViewbizPageModule)
  },
  {
    path: 'viewbiz/:id',
    loadChildren: () => import('./components/viewbiz/viewbiz.module').then( m => m.ViewbizPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./components/aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./components/contactus/contactus.module').then( m => m.ContactusPageModule)
  },
  {
    path: 'myfavs',
    loadChildren: () => import('./components/myfavs/myfavs.module').then( m => m.MyfavsPageModule)
  },
  {
    path: 'catview',
    loadChildren: () => import('./components/catview/catview.module').then( m => m.CatviewPageModule)
  },
  {
    path: 'catview/:id',
    loadChildren: () => import('./components/catview/catview.module').then( m => m.CatviewPageModule)
  },
  {
    path: 'searchview',
    loadChildren: () => import('./components/searchview/searchview.module').then( m => m.SearchviewPageModule)
  },
  {
    path: 'searchview/:sterms',
    loadChildren: () => import('./components/searchview/searchview.module').then( m => m.SearchviewPageModule)
  },
  {
    path: 'addproducts',
    loadChildren: () => import('./components/addproducts/addproducts.module').then( m => m.AddproductsPageModule)
  },
  {
    path: 'addproducts/:id',
    loadChildren: () => import('./components/addproducts/addproducts.module').then( m => m.AddproductsPageModule)
  },
  {
    path: 'booknow',
    loadChildren: () => import('./components/booknow/booknow.module').then( m => m.BooknowPageModule)
  },
  {
    path: 'booknow/:id/:idtext',
    loadChildren: () => import('./components/booknow/booknow.module').then( m => m.BooknowPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./components/bookings/bookings.module').then( m => m.BookingsPageModule)
  },
  {
    path: 'successpage',
    loadChildren: () => import('./components/successpage/successpage.module').then( m => m.SuccesspagePageModule)
  },
  {
    path: 'agentsection',
    loadChildren: () => import('./components/agentsection/agentsection.module').then( m => m.AgentsectionPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
